.App{
    width: 40%;
    margin: 2em auto;
    background-color: #394049;
    border-radius: 5px;
    color:#fff;

}
.todo-header{
    padding: 0.5em;
    border-bottom: 1px solid #2c3035;
    text-align: center;
}
.todo-header h1{
    margin: 0;
}

.form-todo{
    padding: 1em;
    margin: 0 2em;
    border-bottom: 1px solid #2c3035;

}
.form-todo h2,
.list-todo h2{
    text-align: center;
    margin-top: 0;
}

.form-control{
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
  
}

.form-control label{
    font-weight: bold;
    font-size: .7em;
    margin-bottom: .4em;

}

.form-control input{
    border: 1px solid #222;
    border-radius: 2px;
    background-color: #2c3035;
    padding: .5em;
    color: #ffffff;

}

input[type="submit"]{
    background-color: #333;
    color: #eee;
    display: block;
    width: 100%;
    border: 1px solid #222;
    border-radius: 5px;
    padding: 1em;
    cursor: pointer;
}

.list-todo{
    padding: 0 1em 2em;
    margin: 1em 2em;
}

.list-todo h2{
    margin-bottom: 0;
}

.list-todo p{
    text-align: center;
}

.todo{
    border-bottom: 1px solid #444;
    padding: 1em 0;
}

.todo h3{
    margin-bottom: 1em;
}

.todo-done{
    text-decoration: line-through;
    color: rgb(240,98,146);    
}

.todo p{
    text-align: left;
    font-style: italic;
    font-size: .7em;
    margin-bottom: 1.5em;
}

.todo svg{
    font-size: 1.2em;
    cursor: pointer;
    margin-right: 1em;
    transition: .5s;
}

.todo svg:hover{
    color: rgb(240,98,146)
}