html{
  background: linear-Gradient(
    195deg,
    rgb(223, 223, 223) 0%,
    rgb(197, 186, 190) 98%
  );
}

body{
  margin: 0;
  padding: 0;
  font-family: helvetica;
  
}
